var pathname = window.location.pathname;
if (pathname == "/") {
  window.onscroll = function() {scrollFunction()};
}

function scrollFunction() {
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    document.getElementById("navbar").style.backgroundColor = "#fff";
    document.getElementById("navbar").style.boxShadow =  "0 2px 4px 0 rgba(0,0,0,.2)";
    document.getElementsByClassName("nav-link")[0].style.color = "#000";
    document.getElementsByClassName("nav-link")[1].style.color = "#000";
    // document.getElementsByClassName("nav-link")[2].style.color = "#000";
    // document.getElementsByClassName("nav-link")[3].style.color = "#000";
    document.getElementById("navbar-brand").style.display = "none";
    document.getElementById("navbar-brand-dark").style.display = "inherit";
    document.getElementById("navbar-profile-name").style.color = "#000";
  } else {
    document.getElementById("navbar").style.backgroundColor = "#271D3D";
    document.getElementById("navbar").style.boxShadow =  "0 0px 0px 0 rgba(0,0,0,0)";
    document.getElementsByClassName("nav-link")[0].style.color = "#fff";
    document.getElementsByClassName("nav-link")[1].style.color = "#fff";
    // document.getElementsByClassName("nav-link")[2].style.color = "#fff";
    // document.getElementsByClassName("nav-link")[3].style.color = "#fff";
    document.getElementById("navbar-brand").style.display = "inherit";
    document.getElementById("navbar-brand-dark").style.display = "none";
    document.getElementById("navbar-profile-name").style.color = "#fff";
  }
}
